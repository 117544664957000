import { Button, Dialog, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import LoadingScreen from '../../../_components/LoadingScreen'
import VisuallyHiddenInput from '../../../_components/_form/VisuallyHiddenInput'
import DefaultAxios from '../../../_utils/DefaultAxios'
import { IValidationAlias, IValidationErrors, IValidationRules, validateData } from '../../../_utils/Validation'
import { generalErrorHandler, renderToastSuccess } from '../../../_utils/Helper'
import { IBanner } from '../PillarBannerList'

type Props = {
    open: boolean
    onClose: (refresh: boolean) => void
    defaultValue: IBanner | null
}

interface IState {
    url: string
    file: File | null | string
    file_mobile: File | null | string
}

const initialState = {
    url: '',
    file: null,
    file_mobile: null,
}

const PilarBannerAddModal = (props: Props) => {
    const [state, setState] = useState<IState>(initialState)
    const [errorState, setErrorState] = useState<IValidationErrors>({})
    const [isLoading, setIsLoading] = useState(false)

    const editId = useMemo(() => props.defaultValue ? props.defaultValue.id : null, [props.defaultValue])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    const rules: IValidationRules = {
        url: 'nullable',
        file: editId ? 'nullable' : 'required',
        file_mobile: editId ? 'nullable' : 'required',
    }

    const alias: IValidationAlias = {
        url: "URL",
        file: "Banner Desktop",
        file_mobile: "Banner Mobile",
    }

    const handleSubmit = () => {
        const { isValid, errors } = validateData(state, rules, alias)

        if (!isValid) {
            return setErrorState(errors)
        }

        setIsLoading(true)
        setErrorState({})

        const fd = new FormData()
        if (state.url) fd.append('url', state.url)
        if (state.file instanceof File) fd.append('file', state.file as File)
        if (state.file_mobile instanceof File) fd.append('file_mobile', state.file_mobile as File)

        let axios;
        if (editId) {
            axios = DefaultAxios.patch(`${process.env.REACT_APP_API_URL}/pilar-banner/${editId}`, {
                url: state.url
            })
        } else {
            axios = DefaultAxios.post(`${process.env.REACT_APP_API_URL}/pilar-banner`, fd)
        }
        axios
            .then(res => {
                renderToastSuccess(`Berhasil ${editId ? "mengubah" : "menambahkan"} banner!`)
                props.onClose(true)
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        if (!props.open) {
            setState(initialState)
            setErrorState({})
        }
    }, [props.open])

    useEffect(() => {
        if (props.defaultValue) {
            setState(prev => ({
                ...prev,
                file: props.defaultValue!.file,
                url: props.defaultValue!.url || ""
            }))
        }
    }, [props.defaultValue])

    return (
        <Dialog
            open={props.open}
            onClose={() => props.onClose(false)}
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle>{editId ? 'Edit' : 'Add'} Banner</DialogTitle>
            <DialogContent>
                <LoadingScreen open={isLoading} />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            name="url"
                            label="URL"
                            onChange={handleChange}
                            value={state.url}
                            fullWidth
                        />
                    </Grid>
                    {
                        editId ?
                            null :
                            <>
                                <Grid item xs={12}>
                                    <Button component="label" color='inherit' variant="contained">
                                        Upload Banner Desktop
                                        <VisuallyHiddenInput
                                            type="file"
                                            multiple={false}
                                            accept="image/jpeg,image/gif,image/png,image/webp"
                                            onChange={(e) => {
                                                if (e.target.files?.length) {
                                                    setState(prev => ({
                                                        ...prev,
                                                        file: e.target.files && e.target.files.length ? e.target.files[0] : null
                                                    }))
                                                }
                                            }}
                                        />
                                    </Button>
                                    {
                                        errorState.file ?
                                            <Typography display={"block"} sx={{ mt: 1 }} color={"red"} variant='caption'>{errorState.file}</Typography> :
                                            null
                                    }
                                </Grid>
                                {
                                    (state.file !== null) ?
                                        <Grid item xs={12}>
                                            <Typography><em>
                                                {state.file instanceof File ? state.file.name : state.file}
                                            </em></Typography>
                                        </Grid> :
                                        null
                                }
                                <Grid item xs={12}>
                                    <Button component="label" color='inherit' variant="contained">
                                        Upload Banner Mobile
                                        <VisuallyHiddenInput
                                            type="file"
                                            multiple={false}
                                            accept="image/jpeg,image/gif,image/png,image/webp"
                                            onChange={(e) => {
                                                if (e.target.files?.length) {
                                                    setState(prev => ({
                                                        ...prev,
                                                        file_mobile: e.target.files && e.target.files.length ? e.target.files[0] : null
                                                    }))
                                                }
                                            }}
                                        />
                                    </Button>
                                    {
                                        errorState.file_mobile ?
                                            <Typography display={"block"} sx={{ mt: 1 }} color={"red"} variant='caption'>{errorState.file_mobile}</Typography> :
                                            null
                                    }
                                </Grid>
                                {
                                    (state.file_mobile !== null) ?
                                        <Grid item xs={12}>
                                            <Typography><em>
                                                {state.file_mobile instanceof File ? state.file_mobile.name : state.file_mobile}
                                            </em></Typography>
                                        </Grid> :
                                        null
                                }
                            </>
                    }

                    <Grid item xs={12}>
                        <Button
                            onClick={handleSubmit}
                            variant='contained'
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog >
    )
}

export default PilarBannerAddModal