import { Button, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Theme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DefaultAxios from '../../_utils/DefaultAxios'
import { generalErrorHandler, renderToastSuccess } from '../../_utils/Helper'
import LoadingScreen from '../../_components/LoadingScreen'
import { createStyles, makeStyles } from '@mui/styles'
import { Delete, Edit } from '@mui/icons-material'
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import PilarBannerAddModal from './_component/PilarBannerAddModal'
import ImagePreview from '../../_components/ImagePreview'

type Props = {}

export interface IBanner {
    id: number,
    sort: number,
    file: string
    file_mobile: string
    url: string | null,
    created_at: string
    updated_at: string
    file_url: string
    file_mobile_url: string
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    actionButtonContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10
    }
}))

const PillarBannerList = (props: Props) => {
    const classes = useStyles()
    const [isLoading, setIsLoading] = useState(false)
    const [banners, setBanners] = useState<IBanner[]>([])
    const [modalOpen, setModalOpen] = useState(false)
    const [imagePreview, setImagePreview] = useState('')
    const [selectedBanner, setSelectedBanner] = useState<IBanner | null>(null)

    const fetchData = () => {
        setIsLoading(true)
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/pilar-banner`)
            .then(res => res.data)
            .then(res => {
                setBanners(res)
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        fetchData()
    }, [])

    const deleteImage = (id: number) => {
        setIsLoading(true)
        DefaultAxios.delete(`${process.env.REACT_APP_API_URL}/pilar-banner/${id}`)
            .then(res => res.data)
            .then(res => {
                renderToastSuccess("Berhasil hapus banner!")
                fetchData()
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    const sortBanner = (id: number, dir: string) => {
        setIsLoading(true)
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/pilar-banner/sort`, {
            id, dir
        })
            .then(res => res.data)
            .then(res => {
                renderToastSuccess("Berhasil Ubah Sort!")
                fetchData()
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    const renderAction = (banner: IBanner, index: number) => {
        const handleAction = (action: string) => {
            if (action === 'delete') return deleteImage(banner.id)
            if (action === 'edit') return setSelectedBanner(banner)

            sortBanner(banner.id, action)
        }
        return (
            <div className={classes.actionButtonContainer}>
                <IconButton color='primary' disabled={index === 0} onClick={() => handleAction('top')}><VerticalAlignTopIcon /></IconButton>
                <IconButton color='primary' disabled={index === 0} onClick={() => handleAction('up')}><ArrowUpwardIcon /></IconButton>
                <IconButton color='primary' disabled={index === banners.length - 1} onClick={() => handleAction('down')}><ArrowDownwardIcon /></IconButton>
                <IconButton color='primary' disabled={index === banners.length - 1} onClick={() => handleAction('bottom')}><VerticalAlignBottomIcon /></IconButton>
                <IconButton color='primary' onClick={() => handleAction('delete')}><Delete /></IconButton>
                <IconButton color='primary' onClick={() => handleAction('edit')}><Edit /></IconButton>
            </div>
        )
    }

    return (
        <>
            <PilarBannerAddModal
                open={modalOpen || selectedBanner !== null}
                onClose={(refresh) => {
                    if (refresh) {
                        fetchData()
                    }

                    setModalOpen(false)
                    setSelectedBanner(null)
                }}
                defaultValue={selectedBanner || null}
            />
            <LoadingScreen open={isLoading} fullScreen />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Button
                        variant='contained'
                        onClick={() => setModalOpen(true)}
                    >
                        Add Banner
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Desktop</TableCell>
                                <TableCell>Mobile</TableCell>
                                <TableCell>URL</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                banners.length ?
                                    banners.map((banner, index) => (
                                        <TableRow>
                                            <TableCell>
                                                <img
                                                    onClick={() => setImagePreview(banner.file_url)}
                                                    style={{
                                                        width: 377,
                                                        height: 100,
                                                        objectFit: 'cover',
                                                        objectPosition: 'center',
                                                        cursor: 'pointer'
                                                    }}
                                                    src={banner.file_url}
                                                    alt="banner"
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <img
                                                    onClick={() => setImagePreview(banner.file_mobile_url)}
                                                    style={{
                                                        width: 143,
                                                        height: 100,
                                                        objectFit: 'cover',
                                                        objectPosition: 'center',
                                                        cursor: 'pointer'
                                                    }}
                                                    src={banner.file_mobile_url}
                                                    alt="banner mobile"
                                                />
                                            </TableCell>
                                            <TableCell>{banner.url || '-'}</TableCell>
                                            <TableCell>
                                                {renderAction(banner, index)}
                                            </TableCell>
                                        </TableRow>
                                    )) :
                                    <TableRow>
                                        <TableCell colSpan={4}>Tidak ada Banner yang ditampilkan</TableCell>
                                    </TableRow>
                            }
                            <TableRow>

                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>

            <ImagePreview
                image={imagePreview}
                onClose={() => setImagePreview("")}
            />
        </>
    )
}

export default PillarBannerList